import * as Yup from "yup";
import { convertISOToTime, convertToLocalTime } from "../reportCommonHelpers";

export const DAYS = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export const daysMapping = {
  Sun: 0,
  Mon: 1,
  Tue: 2,
  Wed: 3,
  Thu: 4,
  Fri: 5,
  Sat: 6
};

type objects = { name: string }[];

export const sortObjectAlphabetically = (objects: objects) => {
  if (!objects?.length) return null;

  return [...objects].sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  );
};

export const scheduleReportsFormInitialValues = {
  recipients: [],
  objectType: [],
  object: [],
  body: "",
  deliveryFrequencyType: 0,
  deliveryTime: "06:00:00",
  reportType: 2,
  key: [],
  startTime: null,
  endTime: null,
  exportType: 0,
  daysOfWeek: [],
  assignedToAllObjects: true,
  rideModes: []
};

export const transformScheduleDataForForm = (specificSchedule: {
  reportDefinition: {
    startTime: string;
    endTime: string;
    object: [];
    rideModes: [];
  };
  assignedToAllObjects: boolean;
  deliveryTime: string;
}) => {
  const { reportDefinition, assignedToAllObjects, deliveryTime, ...rest } =
    specificSchedule;
  const { startTime, endTime, object, rideModes } = reportDefinition;

  return {
    ...rest,
    ...reportDefinition,
    startTime: convertISOToTime(startTime),
    endTime: convertISOToTime(endTime),
    assignedToAllObjects: object?.length ? false : true,
    deliveryTime: convertToLocalTime(deliveryTime),
    rideModes: rideModes?.map(String) || []
  };
};

export const scheduleReportsFormValidationSchema: Yup.Schema = Yup.object({
  recipients: Yup.array().of(Yup.string()).min(1, "Value is Required"),
  reportType: Yup.number().required("Value is Required"),
  deliveryFrequencyType: Yup.number().required("Value is Required"),
  exportType: Yup.number().required("Value is Required")
});

export function getNextDeliveryTime(
  daysOfWeek: number[],
  deliveryTime: string,
  deliveryFrequencyType: number
): string {
  const currentDate = new Date();
  const [deliveryHours, deliveryMinutes] = deliveryTime.split(":").map(Number);

  let nextDeliveryDate = new Date(currentDate);

  switch (deliveryFrequencyType) {
    case 0:
      nextDeliveryDate = getNextDailyDelivery(
        nextDeliveryDate,
        deliveryHours,
        deliveryMinutes
      );
      break;
    case 1:
      nextDeliveryDate = getNextWeeklyDelivery(
        nextDeliveryDate,
        daysOfWeek,
        deliveryHours,
        deliveryMinutes
      );
      break;
    case 2:
      nextDeliveryDate = getNextMonthlyDelivery(
        nextDeliveryDate,
        daysOfWeek,
        deliveryHours,
        deliveryMinutes
      );
      break;
  }

  return formatDate(nextDeliveryDate)
    ? `Next: ${formatDate(nextDeliveryDate)}`
    : "-";
}

function getNextDailyDelivery(
  date: Date,
  hours: number,
  minutes: number
): Date {
  const nextDate = new Date(date);
  nextDate.setHours(hours, minutes, 0, 0);
  if (date >= nextDate) {
    return addDays(nextDate, 1);
  }
  return nextDate;
}

function getNextWeeklyDelivery(
  date: Date,
  daysOfWeek: number[],
  hours: number,
  minutes: number
): Date {
  const currentDay = date.getDay();

  const daysUntilNextMonday = (1 - currentDay + 7) % 7;

  let nextDate = addDays(date, daysUntilNextMonday);
  nextDate.setHours(hours, minutes, 0, 0);

  if (daysUntilNextMonday === 0 && date >= nextDate) {
    nextDate = addDays(nextDate, 7);
  }

  return nextDate;
}

function getNextMonthlyDelivery(
  date: Date,
  daysOfMonth: number[],
  hours: number,
  minutes: number
): Date {
  const currentDate = date.getDate();
  const currentMonth = date.getMonth();
  const currentYear = date.getFullYear();

  let nextDate: Date;

  if (currentDate === 1) {
    const firstOfMonth = new Date(
      currentYear,
      currentMonth,
      1,
      hours,
      minutes,
      0,
      0
    );
    if (date < firstOfMonth) {
      return firstOfMonth;
    }
  }

  // Move to the 1st of the next month
  if (currentMonth === 11) {
    nextDate = new Date(currentYear + 1, 0, 1, hours, minutes);
  } else {
    nextDate = new Date(currentYear, currentMonth + 1, 1, hours, minutes);
  }

  return nextDate;
}

function addDays(date: Date, days: number): Date {
  const result = new Date(date);
  result.setDate(date.getDate() + days);
  return result;
}

function formatDate(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false // 24-hour format
  };
  return date.toLocaleString("en-US", options);
}
