import { Box, Stack, Typography } from "@mui/material";
import {
  Badge,
  TableColumn,
  types,
  ObjectMetadataTags,
  utils
} from "@vilocnv/allsetra-core";
import { Clock, DriverIcon, MileageIcon, MovingArrowLeft } from "assets/icons";
import "moment/locale/nl";
import {
  OBJECT_RIDE_MODES,
  OBJECT_RIDE_MODES_COLOR,
  getObjectRideTotalTimeInMinutes,
  getSplittedAddress
} from "../helpers";
import moment from "moment";
import { round, compact } from "lodash";
import { Fragment } from "react";
import { IFeatures } from "../types";

//
// TABLE HEADERS
//
export const ALL_OBJECTS_TABLE_COLUMNS = (
  translator: any,
  lang = "en"
): TableColumn<any>[] => {
  moment.locale(lang);
  return [
    {
      name: translator("tableHeading.object", {
        ns: "tableHeadingsTranslation"
      }),
      selector: (row: any) => (
        <Stack gap={0.5} sx={{ pointerEvents: "none" }}>
          <Stack direction={"row"} gap={1} alignItems={"flex-start"}>
            <img src={row.icon?.url} alt="Icon" width="24px" height="24px" />
            <Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#1D1F2B"
                }}
              >
                {row.name || "N/A"}
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#646D7B"
                }}
              >
                ID {row?.aNumber || "N/A"}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      ),
      wrap: true,
      sortable: true,
      sortField: "name"
    },
    // {
    //   name: translator("tableHeading.objectType", {
    //     ns: "tableHeadingsTranslation"
    //   }),
    //   selector: (row: any) => (
    //     <Stack gap={1} sx={{ pointerEvents: "none" }}>
    //       {row.objectTypeName || "N/A"}
    //     </Stack>
    //   ),
    //   sortable: true,
    //   sortField: "objectTypeName"
    // },
    {
      name: translator("tableHeading.state", {
        ns: "tableHeadingsTranslation"
      }),
      selector: (row: any) => (
        <ObjectMetadataTags
          translator={translator}
          object={row}
          direction={"row"}
          isInTable
        />
      ),
      width: "200px"
    },
    {
      name: translator("tableHeading.lastReportedDriver", {
        ns: "tableHeadingsTranslation"
      }),
      cell: (row: any) => (
        <Stack
          direction={"row"}
          gap={1}
          alignItems={"center"}
          sx={{ pointerEvents: "none" }}
        >
          {/* <DriverIcon /> */}
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              color: "#1D1F2B"
            }}
          >
            {row.lastReportedDriver ? `${row.lastReportedDriver}` : "N/A"}
          </Typography>
        </Stack>
      )
    },
    {
      name: translator("tableHeading.lastLocation", {
        ns: "tableHeadingsTranslation"
      }),
      selector: (row: any) => (
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "400",
            color: "#1D1F2B",
            pointerEvents: "none"
          }}
        >
          {getSplittedAddress(0, row.location?.resolvedAddress)}
          <br />
          {getSplittedAddress(1, row.location?.resolvedAddress) +
            getSplittedAddress(2, row.location?.resolvedAddress)}
        </Typography>
      ),
      wrap: true
    },
    {
      name: translator("tableHeading.updated", {
        ns: "tableHeadingsTranslation"
      }),
      selector: (row: any) => {
        return (
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              color: "#1D1F2B",
              pointerEvents: "none"
            }}
          >
            {moment(row.lastUpdated).fromNow()}
          </Typography>
        );
      },
      sortable: true,
      sortField: "lastUpdated"
    },
    {
      name: translator("tableHeading.installationDate", {
        ns: "tableHeadingsTranslation"
      }),
      selector: (row: any) => {
        return (
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              color: "#1D1F2B",
              pointerEvents: "none"
            }}
          >
            {row?.installationDate
              ? moment(row?.installationDate).format("YYYY-MM-DD HH:MM:SS")
              : "N/A"}
          </Typography>
        );
      },
      sortable: true,
      sortField: "installationDate"
    }
  ];
};

export const OBJECT_RIDES_TABLE_COLUMNS = (
  translator: any,
  showMovementRides: boolean = false
): TableColumn<types.IObjectRide>[] => [
  {
    name: translator("tableHeading.rideType", {
      ns: "tableHeadingsTranslation"
    }),
    selector: (row: types.IObjectRide) => (
      <Stack spacing={2}>
        <Stack>
          {/* @ts-ignore */}
          <Badge colorScheme={OBJECT_RIDE_MODES_COLOR[row.rideMode] ?? "error"}>
            {/* @ts-ignore */}
            <div>{OBJECT_RIDE_MODES(translator)[row.rideMode] ?? "N/A"}</div>
          </Badge>
        </Stack>
      </Stack>
    ),
    sortable: true,
    wrap: true,
    width: "150px"
  },

  {
    name: translator("tableHeading.rideStart", {
      ns: "tableHeadingsTranslation"
    }),
    selector: (row: any) => (
      <Stack spacing={2}>
        <Stack direction={"row"} gap={1} alignItems={"center"}>
          <div>{utils.formatDate({ date: row?.startDate })}</div>
          {row?.startedWithoutIgnition && showMovementRides && (
            <MovingArrowLeft
              label={translator("common.movementRide") ?? "Movement ride"}
            />
          )}
        </Stack>
        <div>{row?.startAddress || row.startLatitude || "-"}</div>
      </Stack>
    ),
    sortable: true,
    wrap: true
  },
  {
    name: translator("tableHeading.rideEnd", {
      ns: "tableHeadingsTranslation"
    }),
    selector: (row: types.IObjectRide) => (
      <Stack spacing={2}>
        {row.status === 1 ? (
          <Badge colorScheme={"purple"}>Ride in progress</Badge>
        ) : (
          <Fragment>
            <div>{utils.formatDate({ date: row?.endDate })}</div>
            <div>{row.endAddress || row?.endLatitude || "-"}</div>
          </Fragment>
        )}
      </Stack>
    ),
    sortable: true,
    wrap: true
  },
  {
    name: translator("tableHeading.tags", {
      ns: "tableHeadingsTranslation"
    }),
    selector: (row: types.IObjectRide) => (
      <Stack spacing={2}>
        {row.status !== 1 ? (
          <>
            <Badge colorScheme={"info"} icon={<Clock />} textVariant={"small"}>
              {getObjectRideTotalTimeInMinutes(row)} {"min"}
            </Badge>
            <Badge
              colorScheme={"info"}
              icon={<MileageIcon />}
              textVariant={"small"}
            >
              {round(row.mileage || 0, 1)} {"km"}
            </Badge>
          </>
        ) : null}
      </Stack>
    ),
    sortable: true,
    width: "150px"
  },
  {
    name: translator("tableHeading.keyLabel", {
      ns: "tableHeadingsTranslation"
    }),
    selector: (row: types.IObjectRide) =>
      //@ts-ignore
      row.key?.label || row?.rawKeyId || "N/A",
    sortable: true,
    width: "150px"
  }
];

export const IgnitionColorSchemes: any = {
  On: "info",
  Off: "error",
  null: "grey",
  "N/A": "grey"
};

export const IgnitionColorValues: any = {
  On: "#0269EC",
  Off: "#CC1010",
  null: "#959EB2",
  "N/A": "#959EB2"
};

//
// TAB PANES HEADINGS
//
export const OBJECTS_DETAILS_TABS_HEADINGS = (
  translator: any,
  features: IFeatures
): string[] =>
  compact([
    translator("common.details"),
    features.isRidesEnabled ? translator("common.rides") : null
  ]);

export const OBJECTS_DETAILS_TABS_INDEX_TO_ROUTENAME_MAPPING: Record<
  number,
  string
> = {
  0: "details",
  1: "rides"
};
