import { combineReducers } from "@reduxjs/toolkit";
import dashboardReducer from "./dashboard/dashboardSlice";
import accountsReducer from "./accounts/accountsSlice";
import usersReducer from "./users/usersSlice";
import fietsgestolenReducer from "./fietsgestolen/fietsgestolenSlice";
import activationPortalReducer from "./activationPortal/activationPortalSlice";
import settingsReducer from "./settings/settingsSlice";
import geozonesReducer from "./geozones/geozonesSlice";
import objectsReducer from "./objects/objectsSlice";
import objectTypesReducer from "./objectTypes/objectTypesSlice";
import reportsReducer from "./reports/reportsSlice";
import keysManagerReducer from "./keysManager/keysManagerSlice";
import whiteLabelsReducer from "./whiteLabels/whiteLabelsSlice";
import pointsOfInterestReducer from "./pointsOfInterest/pointsOfInterestSlice";
import scheduleReportsReducer from "./scheduleReports/scheduleReportsSlice";
import exportedReportsHistoryReducer from "./exportedReportsHistory/exportedReportsHistorySlice";
import termsReducer from "./terms/termsSlice";

const rootReducer = combineReducers({
  dashboardReducer,
  accountsReducer,
  usersReducer,
  fietsgestolenReducer,
  activationPortalReducer,
  settingsReducer,
  geozonesReducer,
  objectsReducer,
  objectTypesReducer,
  reportsReducer,
  keysManagerReducer,
  whiteLabelsReducer,
  pointsOfInterestReducer,
  scheduleReportsReducer,
  exportedReportsHistoryReducer,
  termsReducer
});

export default rootReducer;

export type RootReducer = ReturnType<typeof rootReducer>;
