import axiosInstance from "app/axiosInstance";

interface TermsResponse {
  uniqueId: string;
  link: string;
  lastUpdated: string;
}

class TermsService {
  static getLastTermsAndConditionsLink = async (
    whiteLabelId?: string
  ): Promise<TermsResponse> => {
    const response = await axiosInstance.get<TermsResponse>(
      `/terms${whiteLabelId ? `?whiteLabelId=${whiteLabelId}` : ""}`
    );
    return response.data;
  };

  static getAcceptedTermsAndConditions = async (
    termsId: string
  ): Promise<string | null> => {
    const response = await axiosInstance.get<string>(`/termsUser/${termsId}`);
    return response.data;
  };

  static acceptTermsAndConditions = async (termsId: string) => {
    return await axiosInstance.post(`/termsUser/${termsId}`);
  };
}

export default TermsService;
