import React, { FC, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAppDispatch } from "hooks";
import {
  getSideMenuObjectsByQueryThunk,
  setSideMenuObjectsPageNumber
} from "app/features";
import ObjectListingCard from "components/cards/ObjectLIstingCard/ObjectListingCard";

import { Skeleton } from "./Object.styled";
import { useMapState } from "components/maps/Map/v2/GoogleMap/MapStateContext";
import { useSearchParams } from "react-router-dom";
import { isEmpty } from "lodash";

interface MapListingProps {
  drawerSelectedAccountId: string;
  setMapZoom: any;
  setMapPanTo: any;
  mapFilters: any;
  sideMenuObjectsPageNumber?: any;
  sideMenuObjects?: any;
  objects?: any;
  sideMenuObjectsLoading?: any;
  totalSideMenuRecords?: any;
}

const ObjectsMapListing: FC<MapListingProps> = ({
  drawerSelectedAccountId,
  sideMenuObjectsPageNumber,
  sideMenuObjects,
  objects,
  sideMenuObjectsLoading,
  totalSideMenuRecords
}) => {
  const dispatch = useAppDispatch();
  const { onObjectChange } = useMapState();
  const [searchParams] = useSearchParams();
  const isFetching = useRef(false);

  const loadMoreObjects = () => {
    //@ts-ignore
    if (sideMenuObjects.length < totalSideMenuRecords) {
      const nextPage = Math.ceil(sideMenuObjects.length / 25) + 1;

      dispatch(
        getSideMenuObjectsByQueryThunk({
          accountId: drawerSelectedAccountId,
          params: {
            itemsPerPage: 25,
            page: nextPage,
            where: [{ field: "name", value: "", type: 0 }]
          }
        })
      );

      dispatch(setSideMenuObjectsPageNumber(nextPage));
    }
  };

  const fetchSideMenuObjectsData = async () => {
    isFetching.current = true;
    await dispatch(
      getSideMenuObjectsByQueryThunk({
        accountId: drawerSelectedAccountId,
        params: {
          itemsPerPage: 25,
          page: sideMenuObjectsPageNumber,
          where: [{ field: "name", value: "", type: 0 }]
        }
      })
    );

    isFetching.current = false;
  };

  useEffect(() => {
    if (!isEmpty(drawerSelectedAccountId)) {
      if (isFetching.current === false) {
        fetchSideMenuObjectsData();
      }
    }
  }, [drawerSelectedAccountId]);

  return (
    <>
      {!sideMenuObjects.length && !sideMenuObjectsLoading ? (
        <Box sx={{ textAlign: "center", fontWeight: 700 }}>
          No Objects available
        </Box>
      ) : (
        <InfiniteScroll
          dataLength={sideMenuObjects.length}
          next={loadMoreObjects}
          //@ts-ignore
          hasMore={sideMenuObjects.length < totalSideMenuRecords}
          loader={
            <Box sx={{ textAlign: "center", p: 1.5 }}>
              <Skeleton width={"100%"} height={40} />
              <Skeleton width={"100%"} height={40} />
              <Skeleton width={"100%"} height={40} />
              <Skeleton width={"100%"} height={40} />
              <Skeleton width={"100%"} height={40} />
            </Box>
          }
          scrollThreshold={0.9}
          endMessage={
            <Box sx={{ textAlign: "center", py: 3, fontWeight: 500 }}>
              No more records to load
            </Box>
          }
          scrollableTarget="scrollableDiv"
        >
          {searchParams.get("search")
            ? objects.map((object: any) => (
                <ObjectListingCard
                  key={object.uniqueId}
                  object={object}
                  onObjectCardClick={onObjectChange}
                />
              ))
            : sideMenuObjects.map((object: any) => (
                <ObjectListingCard
                  key={object.uniqueId}
                  object={object}
                  onObjectCardClick={onObjectChange}
                />
              ))}
        </InfiniteScroll>
      )}
    </>
  );
};

export default React.memo(ObjectsMapListing);
