import { useState, useEffect, useRef } from "react";
import isEqual from "lodash/isEqual";

// Calculate the amount of changes (addition, removal or modification) by comparing the objects
export function useBuffer(markers: any[], forceSend = false) {
  const [bufferedMarkers, setBufferedMarkers] = useState<any[]>([]);
  const isFirstRun = useRef(true);
  const prevMarkersRef = useRef<any[]>([]);

  useEffect(() => {
    const previousMarkers = prevMarkersRef.current;
    let changedCount = 0;

    // Check additions and modifications:
    markers.forEach((marker) => {
      const previousMarker = previousMarkers.find(
        (m) => m.uniqueId === marker.uniqueId
      );
      if (!previousMarker) {
        // New Marker
        changedCount++;
      } else if (!isEqual(marker, previousMarker)) {
        // Existing marker but with changes (even in nested properties)
        changedCount++;
      }
    });

    // Check removals:
    previousMarkers.forEach((marker) => {
      const currentMarker = markers.find((m) => m.uniqueId === marker.uniqueId);
      if (!currentMarker) {
        changedCount++;
      }
    });

    if (isFirstRun.current && markers.length > 0) {
      setBufferedMarkers(markers);
      isFirstRun.current = false;
      prevMarkersRef.current = markers;
      return;
    }

    const delay = changedCount < 100 ? 100 : changedCount;

    const timer = setTimeout(() => {
      setBufferedMarkers(markers);
      prevMarkersRef.current = markers;
    }, delay);

    return () => clearTimeout(timer);
  }, [markers]);

  useEffect(() => {
    if (forceSend) {
      setBufferedMarkers(markers);
      prevMarkersRef.current = markers;
    }
  }, [forceSend]);

  return bufferedMarkers;
}
