import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { useTheme } from "@mui/material";
import { selectActiveTermsLink } from "app/data/selectors/termsSelectors";
import TermsService from "app/data/services/TermsService";
import { TermsCache } from "app/data/utils/TermsCache";
import {
  getAcceptedTermsAndConditionsThunk,
  getLastTermsAndConditionsLinkThunk
} from "app/features/terms/termsActions";

export const useTermsAndConditions = (whiteLabel: { uniqueId: string }) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const terms = useAppSelector(selectActiveTermsLink);

  const checkAcceptance = useCallback(
    async (uniqueId: string) => {
      const cachedAcceptance = TermsCache.getTermsAcceptance();
      if (cachedAcceptance?.uniqueId === uniqueId) {
        if (
          terms.lastUpdated &&
          new Date(cachedAcceptance.acceptedAt) >= new Date(terms.lastUpdated)
        ) {
          return;
        }
      }

      try {
        return await dispatch(
          getAcceptedTermsAndConditionsThunk(uniqueId)
        ).unwrap();
      } catch (error) {
        console.error("Error checking terms acceptance:", error);
      }
    },
    [dispatch, terms.lastUpdated]
  );

  const fetchAndCheckTerms = useCallback(async () => {
    try {
      const termsResult = await dispatch(
        getLastTermsAndConditionsLinkThunk(whiteLabel?.uniqueId)
      ).unwrap();

      const cachedAcceptance = TermsCache.getTermsAcceptance();
      const needsValidation =
        !cachedAcceptance ||
        cachedAcceptance.uniqueId !== termsResult.uniqueId ||
        (termsResult.lastUpdated &&
          new Date(cachedAcceptance.acceptedAt) <
            new Date(termsResult.lastUpdated));

      if (needsValidation && termsResult.uniqueId) {
        await dispatch(
          getAcceptedTermsAndConditionsThunk(termsResult.uniqueId)
        ).unwrap();
      }
    } catch (error) {
      console.error("Error fetching or checking terms:", error);
    }
  }, [dispatch, whiteLabel?.uniqueId]);

  const handleAcceptTerms = async () => {
    if (!terms.uniqueId) {
      setOpen(false);
      return;
    }

    try {
      await TermsService.acceptTermsAndConditions(terms.uniqueId);
      const acceptedAt = await checkAcceptance(terms.uniqueId);

      if (acceptedAt) {
        TermsCache.saveTermsAcceptance(
          terms.uniqueId,
          new Date().toISOString()
        );
      }

      setOpen(false);
    } catch (error) {
      console.error("Error accepting terms:", error);
    }
  };

  const handleClose = () => setOpen(false);
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setChecked(e.target.checked);

  useEffect(() => {
    fetchAndCheckTerms();
  }, [fetchAndCheckTerms]);

  useEffect(() => {
    if (
      terms.uniqueId &&
      terms.link &&
      !terms.accepted &&
      !terms.loading &&
      !terms.error
    ) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [terms.uniqueId, terms.link, terms.accepted, terms.loading, terms.error]);

  return {
    open,
    terms,
    checked,
    theme,
    handleClose,
    handleAcceptTerms,
    handleCheckboxChange
  };
};
