import { createSlice } from "@reduxjs/toolkit";
import {
  getAcceptedTermsAndConditionsThunk,
  getLastTermsAndConditionsLinkThunk
} from "./termsActions";

export interface ITermsState {
  loading: boolean;
  uniqueId: string | null;
  link: string;
  error: string | null;
  accepted: boolean;
  acceptedAt: string | null;
  lastUpdated: string | null;
}

const initialState: ITermsState = {
  loading: false,
  uniqueId: null,
  link: "",
  error: null,
  accepted: false,
  acceptedAt: null,
  lastUpdated: null
};

const termsSlice = createSlice({
  name: "terms",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLastTermsAndConditionsLinkThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.uniqueId = null;
        state.link = "";
        state.lastUpdated = null;
      })
      .addCase(
        getLastTermsAndConditionsLinkThunk.fulfilled,
        (state, action) => {
          state.loading = false;
          state.uniqueId = action.payload?.uniqueId ?? null;
          state.link = action.payload?.link ?? "";
          state.lastUpdated = action.payload?.lastUpdated ?? null;
          state.error = null;
        }
      )
      .addCase(getLastTermsAndConditionsLinkThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Error fetching terms";
        state.uniqueId = null;
        state.link = "";
        state.lastUpdated = null;
      })
      .addCase(getAcceptedTermsAndConditionsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getAcceptedTermsAndConditionsThunk.fulfilled,
        (state, action) => {
          state.loading = false;
          state.acceptedAt = action.payload;
          state.accepted =
            !!action.payload &&
            new Date(action.payload) >= new Date(state.lastUpdated || "");
        }
      )
      .addCase(getAcceptedTermsAndConditionsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || "Error fetching acceptance status";
      });
  }
});

export default termsSlice.reducer;
