import { FC } from "react";
import { Stack, useTheme } from "@mui/material";
import {
  FormikSelectField,
  FormikSelectSearchField
} from "@vilocnv/allsetra-core";
import DateAndTimeFilters from "../../DateAndTimeFilters/DateAndTimeFilters";

interface Props {
  groups: Array<any>;
  objectTypes: Array<any>;
  objects: Array<any>;
  keys: Array<any>;
  dataLoading?: boolean;
  translator: any;
  fetchOnDebounce: (value: string) => void;
  onChange?: () => void;
  values: any;
}

const InnerForm: FC<Props> = ({
  groups,
  objectTypes,
  objects,
  keys,
  dataLoading = false,
  translator,
  fetchOnDebounce,
  onChange,
  values
}) => {
  const theme = useTheme();
  const commonFieldProps = {
    fullWidth: true
  };
  return (
    <Stack spacing={2}>
      <FormikSelectField
        theme={theme}
        label={translator("objectType.label", { ns: "formFieldsTranslation" })}
        placeholder={translator("objectType.placeholder", {
          ns: "formFieldsTranslation"
        })}
        name="objectType"
        searchable
        {...commonFieldProps}
        options={objectTypes ?? []}
        optionLabelKey="name"
        optionValueKey="uniqueId"
        loading={dataLoading}
        multiple
      />
      {groups?.length ? (
        <FormikSelectField
          theme={theme}
          label={translator("groups.label", { ns: "formFieldsTranslation" })}
          placeholder={translator("groups.placeholder", {
            ns: "formFieldsTranslation"
          })}
          name="group"
          {...commonFieldProps}
          options={groups ?? []}
          optionLabelKey="name"
          optionValueKey="uniqueId"
          loading={dataLoading}
          multiple
          searchable
        />
      ) : (
        <></>
      )}
      <FormikSelectSearchField
        fetchOnDebounce={fetchOnDebounce}
        label={translator("objects.label", { ns: "formFieldsTranslation" })}
        placeholder={translator("objects.placeholder", {
          ns: "formFieldsTranslation"
        })}
        name="object"
        options={objects ?? []}
        {...commonFieldProps}
        optionLabelKey="name"
        optionValueKey="uniqueId"
        loading={dataLoading}
        multiple
        searchable
        onChange={onChange}
      />
      <FormikSelectField
        theme={theme}
        {...commonFieldProps}
        label={translator("keys.label", { ns: "formFieldsTranslation" })}
        placeholder={translator("keys.placeholder", {
          ns: "formFieldsTranslation"
        })}
        name="key"
        options={keys ?? []}
        optionLabelKey="label"
        optionValueKey="uniqueId"
        loading={dataLoading}
        multiple
        searchable
      />
      <DateAndTimeFilters values={values} />
    </Stack>
  );
};

export default InnerForm;
