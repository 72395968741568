export class TermsCache {
  private static CACHE_KEY = "terms_acceptance";

  static saveTermsAcceptance(uniqueId: string, acceptedAt: string): void {
    localStorage.setItem(
      this.CACHE_KEY,
      JSON.stringify({ uniqueId, acceptedAt })
    );
  }

  static getTermsAcceptance(): { uniqueId: string; acceptedAt: string } | null {
    const cached = localStorage.getItem(this.CACHE_KEY);
    return cached ? JSON.parse(cached) : null;
  }

  static clearCache(): void {
    localStorage.removeItem(this.CACHE_KEY);
  }
}
