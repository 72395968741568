import { memo } from "react";
import { Modal } from "@vilocnv/allsetra-core";
import { useTermsAndConditions } from "./useTermsAndConditions";
import { TermsModalContent } from "./TermsModalContent";
import { useTranslation } from "react-i18next";

export interface TermsAndConditionsProps {
  whiteLabel: {
    uniqueId: string;
    whiteLabelAccountName?: string;
  };
}

export const TermsAndConditionsModal = memo(({ whiteLabel }: any) => {
  const {
    open,
    terms,
    checked,
    theme,
    handleClose,
    handleAcceptTerms,
    handleCheckboxChange
  } = useTermsAndConditions(whiteLabel);
  const { t } = useTranslation();

  if (!open) return null;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      theme={theme}
      primaryBtnProps={{
        children: t("termsAndConditions.accept"),
        disabled: !checked || terms.loading,
        onClick: handleAcceptTerms
      }}
      secondaryBtnProps={{
        children: t("termsAndConditions.exit"),
        onClick: handleClose
      }}
    >
      <TermsModalContent
        checked={checked}
        terms={terms}
        whiteLabel={whiteLabel}
        onCheckboxChange={handleCheckboxChange}
      />
    </Modal>
  );
});

TermsAndConditionsModal.displayName = "TermsAndConditionsModal";
