import { memo } from "react";
import { FormControlLabel, Stack, Typography, useTheme } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "react-i18next";
import { TermsIcon } from "assets/icons";

interface TermsModalContentProps {
  checked: boolean;
  terms: {
    link: string;
  };
  whiteLabel: {
    whiteLabelAccountName?: string;
  };
  onCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const TermsModalContent = memo(
  ({
    checked,
    terms,
    whiteLabel,
    onCheckboxChange
  }: TermsModalContentProps) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
      <Stack direction="column" spacing={2}>
        <TermsIcon style={{ alignSelf: "center" }} />
        <Typography variant="h6" alignSelf="center">
          {t("termsAndConditions.title")}
        </Typography>
        <Typography variant="body1">
          {t("termsAndConditions.description")}{" "}
          {whiteLabel?.whiteLabelAccountName ?? "Allsetra"}.
        </Typography>
        <Stack direction="row" alignItems="center">
          <FormControlLabel
            label={
              <Typography sx={{ fontSize: "14px" }}>
                {t("termsAndConditions.agreement")}{" "}
                <Typography
                  component="a"
                  href={terms.link}
                  target="_blank"
                  rel="noreferrer"
                  sx={{
                    fontSize: "14px",
                    color: theme.palette.primary.main,
                    textDecoration: "none",
                    cursor: "pointer",
                    display: "inline",
                    userSelect: "none"
                  }}
                >
                  {t("termsAndConditions.terms")}
                </Typography>
              </Typography>
            }
            control={<Checkbox checked={checked} onChange={onCheckboxChange} />}
          />
        </Stack>
      </Stack>
    );
  }
);

TermsModalContent.displayName = "TermsModalContent";
