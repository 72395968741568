import { createAsyncThunk } from "@reduxjs/toolkit";
import TermsService from "app/data/services/TermsService";

export const getLastTermsAndConditionsLinkThunk = createAsyncThunk(
  "terms/getLastTermsAndConditionsLink",
  async (whiteLabelId: string | undefined) => {
    const response =
      await TermsService.getLastTermsAndConditionsLink(whiteLabelId);
    return response;
  }
);

export const getAcceptedTermsAndConditionsThunk = createAsyncThunk(
  "terms/getAcceptedTermsAndConditions",
  async (termsId: string) => {
    const response = await TermsService.getAcceptedTermsAndConditions(termsId);
    return response;
  }
);
