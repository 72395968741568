import React, { FC, Fragment, useMemo } from "react";
import Marker from "./Marker";
import { useMapState } from "./MapStateContext";
import { useBuffer } from "./useBuffer";

const Markers: FC = () => {
  const { markers, showClusters } = useMapState();

  const bufferedMarkers: any[] = useBuffer(markers, !showClusters);

  const renderMarkers = useMemo(() => {
    return bufferedMarkers.map((marker, index) => (
      <Fragment key={index}>
        <Marker marker={marker} />
      </Fragment>
    ));
  }, [bufferedMarkers]);

  return (
    <>{!showClusters && bufferedMarkers.length > 0 && <>{renderMarkers}</>}</>
  );
};

export default React.memo(Markers);
