import { FC, useEffect, useMemo, useRef, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import {
  Topbar,
  Table,
  useDispatchOnParams,
  MapFilterForm,
  toast,
  ReactTable,
  types
} from "@vilocnv/allsetra-core";
import { useNavigate, useSearchParams } from "react-router-dom";

// Data
import { useAppDispatch, useAppSelector } from "hooks";
import { ALL_OBJECTS_TABLE_COLUMNS } from "app/data/constants";
import {
  getAllAccountGroupsThunk,
  getAllKeysByAccountThunk,
  getObjectsByQueryThunk,
  getObjectsLocationsThunk,
  getObjectTypesByAccountThunk,
  getSideMenuObjectsByQueryThunk,
  resetAllObjects,
  resetSideMenuObjects,
  setActiveObjectId,
  setFilters,
  setObjectActiveTabIndex,
  setObjectsListingView,
  setSpecificObject
} from "app/features";
import {
  selectAccountGroups,
  selectAccountKeysState,
  selectDrawerSelectedAccountId,
  selectMapFilterState,
  selectObjectMapState,
  selectObjectsState,
  selectObjectTypesState,
  selectQueriedObjectsState
} from "app/data/selectors";

import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "app/integrations/microsoftInsights";
import { useTranslation } from "react-i18next";
import { CloseGreenIcon, OpenGreenIcon } from "assets/icons";
import { FormikHelpers } from "formik";
import { isEmpty } from "lodash";
import {
  GridWrapperBox,
  IconStickyBox,
  IconTextBox,
  IconWrapperBox,
  MainWrapperBox,
  ObjectTableBox,
  ScrollableBox
} from "./Object.styled";
import ObjectsMapListing from "./ObjectsMapLIsting";
import { MapStateProvider } from "components/maps/Map/v2/GoogleMap/MapStateContext";
import GoogleMap from "components/maps/Map/v2/GoogleMap";
import DashboardMapTopLeftSection from "components/maps/DashboardMap/DashboardMapTopLeftSection";
import DashboardMapTopRightSection from "components/maps/DashboardMap/DashboardMapTopRightSection";
import TrafficLayer from "components/maps/Map/v2/GoogleMap/TrafficLayer";
import MarkersClusterer from "components/maps/Map/v2/GoogleMap/MarkersClusterer";
import Markers from "components/maps/Map/v2/GoogleMap/Markers";
import Geozone from "components/maps/Map/v2/GoogleMap/Geozone";
import Geozones from "components/maps/Map/v2/GoogleMap/Geozones";
import { extractObjectsDataAsMarkersForMap } from "app/data/helpers";

const Objects: FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Global States
  const {
    objects,
    totalRecords,
    loading,
    sideMenuObjects,
    sideMenuObjectsLoading,
    objectsListingView,
    sideMenuObjectsPageNumber,
    totalSideMenuRecords
  } = useAppSelector(selectQueriedObjectsState);

  const isSideMenuVisible = objectsListingView === "mapListing";

  const { markers } = useAppSelector(selectObjectMapState);

  const { accountKeys, loading: accountKeysLoading } = useAppSelector(
    selectAccountKeysState
  );
  const { objectTypes, loading: objectTypesLoading } = useAppSelector(
    selectObjectTypesState
  );
  const { accountGroups, loading: accountGroupsLoading } =
    useAppSelector(selectAccountGroups);

  const { allObjects, loading: allObjectsLoading } =
    useAppSelector(selectObjectsState);
  const { mapFilters } = useAppSelector(selectMapFilterState);
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [mapZoom, setMapZoom] = useState(7);
  const [mapPanTo, setMapPanTo] = useState({});
  const isFetching = useRef(false);

  const [transformedObjects, setTransformedObjects] = useState([]);

  const [searchParams] = useSearchParams();

  const activeAccountKeys = useMemo(() => {
    return Array.isArray(accountKeys)
      ? accountKeys.filter((key) => key.isActive)
      : [];
  }, [accountKeys]);

  const drawerSelectedAccountId =
    useAppSelector(selectDrawerSelectedAccountId) || "";

  const { t, i18n } = useTranslation([
    "translation",
    "tableHeadingsTranslation"
  ]);

  const tableColumns = useMemo(
    () => ALL_OBJECTS_TABLE_COLUMNS(t, i18n.language),
    [t, i18n.language]
  );

  const mapFiltersSubmitHanlder = async (
    values: types.IMapFilter,
    formikHelpers: FormikHelpers<types.IMapFilter>
  ) => {
    if (!drawerSelectedAccountId) {
      toast.info("Kindly select an account from the navigation drawer.");
      return;
    }

    formikHelpers.setSubmitting(true);
    setSubmitting(true);

    const payload = {
      accountId: drawerSelectedAccountId ?? "",
      values: {
        ...values
      }
    };

    const { type } = await dispatch(getObjectsLocationsThunk(payload));

    if (type === "objects/getObjectsLocationsThunk/fulfilled") {
      formikHelpers.setSubmitting(false);
      setSubmitting(false);
      setFilterOpen(false);
      dispatch(setFilters(values));
    }
  };

  useDispatchOnParams(getObjectsByQueryThunk, {
    args: {
      accountId: drawerSelectedAccountId
    },
    orderBy: [{ field: "name", ascending: true }]
  });

  const fetchFilterValues = async () => {
    isFetching.current = true;

    await dispatch(getAllAccountGroupsThunk(drawerSelectedAccountId || ""));
    await dispatch(getObjectTypesByAccountThunk(drawerSelectedAccountId || ""));
    await dispatch(getAllKeysByAccountThunk(drawerSelectedAccountId || ""));

    isFetching.current = false;
  };

  // useDispatchOnParams(getObjectsLocationsThunk, {
  //   args: {
  //     accountId: drawerSelectedAccountId,
  //     //@ts-ignore
  //     values: mapFilters
  //   }
  //   orderBy: [{ field: "name", ascending: true }]
  // });

  // const fetchSideMenuObjectsData = async () => {
  //   isFetching.current = true;
  //   await dispatch(
  //     getSideMenuObjectsByQueryThunk({
  //       accountId: drawerSelectedAccountId,
  //       params: {
  //         itemsPerPage: 25,
  //         page: sideMenuObjectsPageNumber,
  //         where: [{ field: "name", value: "", type: 0 }]
  //       }
  //     })
  //   );

  //   await dispatch(
  //     getObjectsLocationsThunk({
  //       accountId: drawerSelectedAccountId,
  //       values: mapFilters
  //     })
  //   );

  //   isFetching.current = false;
  // };

  const rowClickHandler = (row: any) => {
    dispatch(setObjectActiveTabIndex(0));
    dispatch(setSpecificObject(null));
    dispatch(setActiveObjectId(row?.uniqueId));
    navigate({
      pathname: `/dashboard/objects/details/${row.uniqueId}`
    });
  };

  useEffect(() => {
    if (!isEmpty(drawerSelectedAccountId && isFetching.current === false)) {
      fetchFilterValues();
    }
    return () => {
      dispatch(setActiveObjectId(null));
      dispatch(resetAllObjects());
      dispatch(resetSideMenuObjects());
    };
  }, [drawerSelectedAccountId]);

  useEffect(() => {
    if (searchParams.get("search")) {
      //@ts-ignore
      setTransformedObjects(extractObjectsDataAsMarkersForMap(objects));
    } else {
      //@ts-ignore
      setTransformedObjects(markers);
    }
  }, [objects, markers]);

  return (
    <MapStateProvider
      objects={allObjects}
      isLoading={allObjectsLoading}
      disableNavigator={true}
      skipCurrentLocation={true}
      showSearch={true}
      showFilter={true}
      isDashboardMap={true}
      objectsMarker={false}
      mapZoom={mapZoom}
      mapPanTo={mapPanTo}
      markers={transformedObjects}
    >
      <MainWrapperBox>
        <GridWrapperBox isSideMenuVisible={isSideMenuVisible}>
          <ScrollableBox id="scrollableDiv">
            <IconStickyBox>
              <IconWrapperBox isSideMenuVisible={isSideMenuVisible}>
                {isSideMenuVisible ? (
                  <IconTextBox
                    onClick={() =>
                      dispatch(setObjectsListingView("tableListing"))
                    }
                  >
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "12px",
                        lineHeight: "26px"
                      }}
                    >
                      Expand objects
                    </Typography>
                    <OpenGreenIcon />
                  </IconTextBox>
                ) : (
                  <IconTextBox
                    onClick={() =>
                      dispatch(setObjectsListingView("mapListing"))
                    }
                  >
                    <CloseGreenIcon />
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "12px",
                        lineHeight: "26px"
                      }}
                    >
                      Collapse objects
                    </Typography>
                  </IconTextBox>
                )}
              </IconWrapperBox>
            </IconStickyBox>
            <Topbar
              theme={theme}
              title={isSideMenuVisible ? "" : t("drawerMenuLinks.objects")}
            />

            {isSideMenuVisible ? (
              <>
                <ObjectsMapListing
                  drawerSelectedAccountId={drawerSelectedAccountId}
                  setMapZoom={setMapZoom}
                  setMapPanTo={setMapPanTo}
                  mapFilters={mapFilters}
                  sideMenuObjectsPageNumber={sideMenuObjectsPageNumber}
                  sideMenuObjects={sideMenuObjects}
                  objects={objects}
                  sideMenuObjectsLoading={sideMenuObjectsLoading}
                  totalSideMenuRecords={totalSideMenuRecords}
                  // isSideMenuVisible={isSideMenuVisible}
                />
              </>
            ) : (
              <ObjectTableBox mx={4} mt={4}>
                <ReactTable
                  columns={tableColumns}
                  data={objects}
                  isSelectable={false}
                  isLoading={loading as any}
                  onRowClicked={rowClickHandler}
                  paginationTotalRows={totalRecords as any}
                  sx={undefined}
                  moduleName="object"
                  tableMainBoxStyles={{
                    height: "calc(100vh - 314px)",
                    border: "1px solid #D5D9E8",
                    borderRadius: "10px",
                    marginBottom: "10vh",
                    padding: "1px"
                  }}
                  mainTableStyles={{}}
                  searchFieldPlaceholder="Search"
                />
                {/* <Table
                  columns={tableColumns}
                  data={objects}
                  progressPending={loading}
                  paginationTotalRows={totalRecords}
                  searchPlaceholder={t("common.search")}
                  onRowClicked={rowClickHandler}
                  sortServer
                />
                /> */}
                {/* <Table
                  columns={tableColumns}
                  data={objects}
                  progressPending={loading}
                  paginationTotalRows={totalRecords}
                  searchPlaceholder={t("common.search")}
                  onRowClicked={rowClickHandler}
                  sortServer
                /> */}
                {/* <Table
                  columns={tableColumns}
                  data={objects}
                  progressPending={loading}
                  paginationTotalRows={totalRecords}
                  searchPlaceholder={t("common.search")}
                  onRowClicked={rowClickHandler}
                  sortServer
                /> */}
              </ObjectTableBox>
            )}
          </ScrollableBox>
          {isSideMenuVisible ? (
            <Box>
              <DashboardMapTopLeftSection showCustomSearchField={true} />
              <GoogleMap>
                <DashboardMapTopRightSection />
                <TrafficLayer />
                <MarkersClusterer showLinkIcon={true} />
                <Markers />
                <Geozone />
                <Geozones />
              </GoogleMap>
            </Box>
          ) : (
            <></>
          )}
        </GridWrapperBox>

        <MapFilterForm
          open={filterOpen}
          onClose={() => setFilterOpen(false)}
          onSubmit={mapFiltersSubmitHanlder}
          groups={accountGroups}
          types={objectTypes}
          keys={activeAccountKeys}
          dataLoading={
            accountKeysLoading || objectTypesLoading || accountGroupsLoading
          }
          theme={theme}
          initialValues={mapFilters}
          translator={t}
          submitting={submitting}
        />
      </MainWrapperBox>
    </MapStateProvider>
  );
};

export default withAITracking(reactPlugin, Objects);
