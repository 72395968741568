import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "app/store";

const selectTermsState = (state: RootState) => state.rootReducer.termsReducer;

export const selectActiveTermsLink = createSelector(
  [selectTermsState],
  (terms) => ({
    link: terms.link,
    uniqueId: terms.uniqueId,
    loading: terms.loading,
    accepted: terms.accepted,
    lastUpdated: terms.lastUpdated,
    error: terms.error
  })
);
