import { Box, Skeleton as MuiSkeleton, styled } from "@mui/material";

export const GroupsMessageBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  fontSize: "20px"
});

export const MainWrapperBox = styled(Box)({
  position: "relative"
});

export const ObjectDiv = styled(Box)({
  "& .MuiBox-root:has(img)": {
    top: "3px"
  },
  "& .MuiBox-root": {
    position: "relative"
  },
  "& img": {
    top: "-4px",
    height: "100%", // Ensures the image stretches to match its container
    maxHeight: "32px", // Restrict the maximum height to 32px
    position: "absolute", // Positioning for flexibility
    objectFit: "contain" // Ensures the image fits within its container
  }
});
export const ScrollableBox = styled(Box)({
  zIndex: 2,
  height: "100vh",
  overflowY: "auto",
  overflowX: "hidden",
  position: "relative",
  backgroundColor: "#FFF"
});

export const IconWrapperBox = styled(Box)<{ isSideMenuVisible: boolean }>(
  ({ isSideMenuVisible }) => ({
    position: isSideMenuVisible ? "absolute" : "absolute",
    right: 10,
    top: 72,
    cursor: "pointer",
    zIndex: 9999,
    filter:
      "drop-shadow(0px 4px 15px rgba(55, 83, 125, 0.16)) drop-shadow(0px 2px 4px rgba(55, 83, 125, 0.16))"
  })
);

export const IconTextBox = styled(Box)({
  boxShadow: "0 0 4px 0 rgba(55, 83, 125, 0.16)",
  borderRadius: "100px",
  padding: "0px 8px 0px 10px",
  display: "flex",
  backgroundColor: "white"
});

export const IconStickyBox = styled(Box)({
  position: "sticky",
  top: "0",
  zIndex: 99999
});

export const SkeletonWrapper = styled(Box)({
  width: "456px"
});

export const Skeleton = styled(MuiSkeleton)<{ height: number; width: string }>(
  ({ height, width }) => ({
    width: width,
    height: height,
    mb: 3,
    backgroundColor: "#d7d7d7"
  })
);

export const GridWrapperBox = styled(Box)<{ isSideMenuVisible: boolean }>(
  ({ theme, isSideMenuVisible }) => ({
    display: "grid",
    gridTemplateColumns: isSideMenuVisible ? "1fr" : "1fr",
    transition: "all 0.3s ease-in-out",
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: isSideMenuVisible ? "490px 1fr" : "1fr"
    }
  })
);

export const ObjectTableBox = styled(Box)({
  "& .rdt_TableRow:hover": {
    backgroundColor: "#F5FFFE"
  }
});
