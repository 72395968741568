import { FC, useEffect, useState, useMemo } from "react";
import { Box, useTheme } from "@mui/material";
import { FormikHelpers } from "formik";
import { Table } from "@vilocnv/allsetra-core";
import CO2ReportExpendableRowCard from "components/cards/ReportCards/CO2ReportExpendableRowCard.tsx/CO2ReportExpendableRowCard";
import ReportsTopbar from "components/common/reports/ReportsTopbar/ReportsTopbar";

// Data
import { useAppDispatch, useAppSelector, useReportCommonStates } from "hooks";
import {
  commonReportsDataFormatter,
  commonReportsFilterFormValidationSchema,
  co2ReportsFilterFormInitialValues,
  convertDatesForFilterPayload,
  convertToNormalTime
} from "app/data/helpers";
import { generateReportThunk } from "app/features/reports/reportsActions";
import {
  getAllKeysByAccountThunk,
  resetReports,
  setReportsFilterData
} from "app/features";
import { useTranslation } from "react-i18next";
import ReportsFilterBar from "components/common/reports/ReportFiltersBar/ReportsFilterBar";
import { selectAccountKeysState } from "app/data/selectors";
import { CO2_REPORT_TABLE_COLUMNS } from "app/data/constants/Reports/co2ReportsConstants";
import ReportsCommonFilterForm from "components/forms/Reports/ReportsCommonFilterForm";

const DEFAULT_FILTER_VALUES = {
  driver: [],
  group: [],
  object: [],
  objectType: []
};

const CO2Reports: FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const {
    drawerSelectedAccountId,
    objectTypes,
    objectTypesLoading,
    objectsLoading,
    accountGroups,
    report,
    reportsLoading,
    filterOpen,
    setFilterOpen,
    dateFormat,
    setDateFormat,
    dateValues,
    setDateValues,
    localReport,
    setLocalReport,
    handleObjectsSearch,
    selectedLocalObjects,
    handleOnChangeReportFilterForm,
    preloadedObjects
  } = useReportCommonStates();

  const [filterValues, setFilterValues] = useState<any>(DEFAULT_FILTER_VALUES);
  const [openModal, setOpenModal] = useState(false);

  const { t } = useTranslation(["translation", "tableHeadingsTranslation"]);

  const { formattedData, isShowKeyCol } = commonReportsDataFormatter(
    localReport,
    "name",
    dateFormat
  );

  const tableColumns = useMemo(
    () => CO2_REPORT_TABLE_COLUMNS(t, isShowKeyCol),
    [t, isShowKeyCol]
  );

  const [apiPayload, setApiPayload] = useState(null);

  const { accountKeys, loading: accountKeysLoading } = useAppSelector(
    selectAccountKeysState
  );

  const co2ReportFiltersSubmitHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true);

    setFilterValues(values);

    const { formattedStartDate, formattedEndDate } =
      convertDatesForFilterPayload(dateValues);

    const payload = {
      ...(apiPayload ?? {}),
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      ...values,
      reportType: 4
    };

    dispatch(setReportsFilterData(payload));
    setApiPayload(payload);

    formikHelpers.setSubmitting(false);
    setFilterOpen(false);
  };

  const handleDateChange = (value: any) => {
    setDateValues(value);
  };

  const handleDateRangeSubmit = () => {
    const startDate = convertToNormalTime(dateValues.startDate);
    const endDate = convertToNormalTime(dateValues.endDate, true);

    const updatedFilterValues = { ...filterValues };

    const payload = {
      ...(apiPayload ?? {}),
      startDate,
      endDate,
      ...updatedFilterValues,
      reportType: 4
    };

    setApiPayload(payload);
  };

  const handleLoadReport = () => {
    dispatch(setReportsFilterData(apiPayload));
    dispatch(generateReportThunk(apiPayload));
  };

  useEffect(() => {
    handleDateRangeSubmit();
    dispatch(resetReports());
  }, []);

  useEffect(() => {
    setLocalReport(report);
  }, [report]);

  useEffect(() => {
    if (drawerSelectedAccountId !== null) {
      dispatch(getAllKeysByAccountThunk(drawerSelectedAccountId));
    }
  }, [drawerSelectedAccountId]);

  return (
    <Box>
      <ReportsTopbar
        dropdownTitle={t("reports.co2Report")}
        disabled={reportsLoading}
      />
      <Box mx={4}>
        <ReportsFilterBar
          setFilterOpen={setFilterOpen}
          dateFormat={dateFormat}
          setDateFormat={setDateFormat}
          handleDateChange={handleDateChange}
          dateValues={dateValues}
          handleDateRangeSubmit={handleDateRangeSubmit}
          dateRangeLoading={reportsLoading}
          disableExportButton={!formattedData?.length}
          reportType={4}
          reportExportTypeValue={2}
          openModal={openModal}
          setOpenModal={setOpenModal}
          exportModalButton={true}
          hideExportButton={true}
          handleLoadReport={handleLoadReport}
        />
        <ReportsCommonFilterForm
          fetchOnDebounce={handleObjectsSearch}
          open={filterOpen}
          onClose={() => setFilterOpen(false)}
          onSubmit={co2ReportFiltersSubmitHandler}
          groups={accountGroups}
          objectTypes={objectTypes}
          objects={
            selectedLocalObjects.length
              ? selectedLocalObjects
              : preloadedObjects
          }
          keys={accountKeys}
          dataLoading={
            objectsLoading || objectTypesLoading || accountKeysLoading
          }
          theme={theme}
          //@ts-ignore
          onChange={handleOnChangeReportFilterForm}
          isCO2InnerForm
          formInitialValues={co2ReportsFilterFormInitialValues}
          formValidations={commonReportsFilterFormValidationSchema}
        />
        {/* <CO2ReportsFilterForm /> */}
        <Table
          columns={tableColumns}
          data={formattedData}
          progressPending={reportsLoading}
          expandableRows
          expandOnRowClicked
          expandableRowsComponent={CO2ReportExpendableRowCard}
          pagination={false}
          nestedRowPadding={"16px"}
          headerPadding={"50px"}
          translator={t}
        />
      </Box>
    </Box>
  );
};

export default CO2Reports;
